import * as React from 'react';
import type { VariantProps } from 'class-variance-authority';
import { AiOutlineLoading } from 'react-icons/ai';
import { useIsSubmitting } from 'remix-validated-form';

import { Button, type buttonVariants } from './Button';

interface SubmitButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

function SubmitButton({ disabled, ...rest }: SubmitButtonProps) {
  const isSubmitting = useIsSubmitting();
  return (
    <Button
      type='submit'
      disabled={isSubmitting || disabled}
      className={`${isSubmitting || disabled ? 'bg-gray-400' : ''}`}
      {...rest}
    >
      {isSubmitting ? <AiOutlineLoading className='mr-2 h-4 w-4 animate-spin' /> : null}
      {rest.children}
    </Button>
  );
}

export { SubmitButton };
